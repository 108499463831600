<template>
  <div>
    <page-header></page-header>
    <div class="layout-main-body">
      <el-card class="box-card" shadow="never">
        <el-row>
          <el-col :span="24">
            <div class="condition-panel mb10">
              <el-radio-group v-model="dateRadio" @change="handleDateRadioChange" class="condition-item">
                <el-radio-button :label="2">本月</el-radio-button>
                <el-radio-button :label="3">上月</el-radio-button>
                <el-radio-button :label="4">全部</el-radio-button>
                <el-radio-button :label="5">自定义</el-radio-button>
              </el-radio-group>
              <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="handleDateRangeChange"
                  class="condition-item date-range"
                  :picker-options="pickerOptions"
              ></el-date-picker>
<!--              <el-select v-model="searchForm.amsDeptId" clearable placeholder="部门" @change="handleDeptChange"
                         class="condition-item dept-select">
                <el-option v-for="item in deptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>-->
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                show-summary
                :summary-method="getSummaries"
                style="width: 100%">
              <el-table-column prop="date" label="排名" type="index"></el-table-column>
              <el-table-column prop="displayName" label="员工姓名"></el-table-column>
              <el-table-column prop="totalVip" label="总数"></el-table-column>
              <el-table-column prop="totalFirstOpen" label="新开"></el-table-column>
              <el-table-column prop="totalReOpen" label="续费"></el-table-column>
              <el-table-column prop="totalV1" label="一年"></el-table-column>
              <el-table-column prop="totalV3" label="三年"></el-table-column>
              <el-table-column prop="totalAmount" label="销售额">
                <template slot-scope="scope">
                  {{ getAmount(scope.row.totalAmount) }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.page"
                :page-sizes="[10,20,50,100]"
                :page-size="searchForm.size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { RptApi, SysDeptApi } from '@/api'
import { getCurrentMonthDate, getPreMonthDate } from '@/utils/date-utils'
import { SHORTCUTS } from '@/utils/contants'
import numeral from 'numeral'

export default {
  name: 'SalesRank',
  data () {
    return {
      deptList: [],
      searchForm: {
        amsDeptId: '',
        text: '',
        size: 10,
        page: 1
      },
      rows: [],
      total: 0,
      loading: false,
      dateRadio: 2,
      dateRange: getCurrentMonthDate(),
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      reportTotal: {}
    }
  },
  methods: {
    initData () {
      SysDeptApi.getAll().then(res => {
        this.deptList = res
      })
    },
    handleSearch () {
      this.loading = true

      const params = {
        ...this.searchForm,
        page: this.searchForm.page - 1
      }
      if (this.dateRange.length > 0) {
        params.startDate = this.dateRange[0]
        params.endDate = this.dateRange[1]
      } else {
        params.startDate = ''
        params.endDate = ''
      }
      RptApi.getEmployeeRank(params).then((res) => {
        this.rows = res.rows
        this.total = res.total
        this.loading = false
      })

      RptApi.getEmployeeRankTotal(params).then((res) => {
        this.reportTotal = res || {}
      })
    },
    handleSizeChange (size) {
      this.searchForm.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.searchForm.page = page
      this.handleSearch()
    },
    handleDateRadioChange (val) {
      switch (val) {
        case 2:
          this.dateRange = getCurrentMonthDate()
          break
        case 3:
          this.dateRange = getPreMonthDate()
          break
        case 4:
        case 5:
          this.dateRange = []
          break
      }
      this.handleSearch()
    },
    handleDateRangeChange () {
      this.dateRadio = 5
      this.handleSearch()
    },
    handleDeptChange () {
      this.handleSearch()
    },
    getSummaries (param) {
      const {
        columns
      } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 1 || index === 2) {
          return
        }
        if (column.property === 'totalAmount') {
          sums[index] = this.getAmount(this.reportTotal[column.property])
        } else {
          sums[index] = this.reportTotal[column.property]
        }
      })

      return sums
    },
    getAmount (amount) {
      return numeral(amount).format('0,0')
    }
  },
  created () {
    this.initData()
    this.handleSearch()
  }
}
</script>

<style scoped lang="less">
.condition-panel {
  display: flex;
  align-items: center;

  .condition-item {
    margin-right: 10px;
  }

  .date-range {
    width: 262px;
  }

  .dept-select {
    width: 200px;
  }
}
</style>
