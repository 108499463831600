import moment from 'moment'

// moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss')
const FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss'
const FORMAT_DATE = 'YYYY-MM-DD'

export const nowTime = () => {
  return moment().format(FORMAT_TIME)
}

export const getCurrentDate = () => {
  const date = moment().format(FORMAT_DATE)
  return [date, date]
}

/**
 * 获取本月日期
 * @returns {[string, string]}
 */
export const getCurrentMonthDate = () => {
  return [
    moment().startOf('month').format(FORMAT_DATE),
    moment().endOf('month').format(FORMAT_DATE)
  ]
}

/**
 * 获取上月日期
 * @returns {[string, string]}
 */
export const getPreMonthDate = () => {
  return [
    moment().subtract(1, 'months').startOf('month').format(FORMAT_DATE),
    moment().subtract(1, 'months').endOf('month').format(FORMAT_DATE)
  ]
}
